<template>
  <div class="custom-input custom-validate-input">
    <label :class="[{'is-not-valid': !data.isValid}, classes || {}]" :style="getStyles">
      <span class="input-label"
            :class="{'is-focus': data.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>
      <textarea
        :class="[align === 'right' ? 'text-left' : 'text-right', {'disabled': disabled}, {'rtl': !rtl}, {'ltr': rtl}]"
        @input="handleInputValue, $emit('input', data)"
        @click="$emit('click')"
        :placeholder="placeholder || ''"
        :disabled="disabled"
        v-model="data.value"/>
    </label>
  </div>
</template>

<script>
export default {
  name: 'customValidateTextarea',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    rtl: {
      type: Boolean,
      default: () => { return null }
    },
    disabled: {
      type: Boolean,
      default: () => { return null }
    },
    label: {
      type: String,
      default: () => { return '' }
    },
    placeholder: {
      type: String,
      default: () => { return '' }
    },
    labelPlaceholder: {
      type: String,
      default: () => { return '' }
    },
    align: {
      type: String,
      default: () => { return 'left' }
    },
    isEmpty: {
      type: Boolean,
      default: () => { return null }
    },
    regex: {
      type: RegExp,
      default: () => { return null }
    },
    value: {
      type: Object,
      default () { return {} }
    },
    classes: {
      type: Object,
      default () { return {} }
    },
    styles: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      data: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.data.isValid = true
    } else {
      this.validateValue()
    }
  },
  methods: {
    handleInputValue () {
      this.data.value = this.data.value.toString().replace(this.regex, '')
      this.validateValue()
      this.$emit('input', {value: this.data.value.toString().replaceAll(',', ''), isValid: this.data.isValid})
    },
    validateValue () {
      if (this.regex !== null && this.data.value.length > 0) {
        this.data.isValid = this.regex.test(this.data.value)
      } else if (this.isEmpty) {
        this.data.isValid = true
      } else {
        this.data.isValid = this.data.value.toString().length > 0
      }
    },
    initValues () {
      this.data.value = this.value.value
      this.validateValue()
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    },
    'value.isValid': {
        handler () {
            this.data.isValid = this.value.isValid
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-validate-input {
  position: relative;
  margin: 15px 0;

  label {
    display: block;
    padding: 6px 5px;
    border: 1px solid #cecece;
    border-radius: .5rem;
    backdrop-filter: sepia(1);
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      textarea {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        background: #ffffff;
        top: -10px;
      }
    }

    textarea {
      width: 100% !important;
      height: 150px;
      resize: none;
      background: transparent;
      border: none;
      outline: none !important;
      text-align: right;

      &.disabled {
        opacity: 0.75;
      }

      &::-webkit-scrollbar {
        display: block;
        background: rgba(206, 206, 206, 0.50);
        width: 10px;
        border-radius: .25rem;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        background: rgba(206, 206, 206, 1);
        border-radius: .25rem;
      }
    }
  }
}
</style>
